<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card :disabled="loading">
          <v-row>
            <v-col cols="12">
              <v-img
                  height="70"
                  src="@/assets/images/oracle2.png"
                  contain
                  style="margin-top: 30px; margin-bottom: 30px;"
              ></v-img>
            </v-col>
          </v-row>

          <v-toolbar dark flat>
            <v-toolbar-title>Suite8</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="getSettings" :loading="loading" :disabled="loading">
              <v-icon>refresh</v-icon>
            </v-btn>
          </v-toolbar>

          <v-toolbar flat>
            <v-toolbar-title>{{ $t('settings.lang_apiSettings') }}</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field dense outlined :label="$t('settings.lang_host')" v-model="apiHost"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field dense outlined :label="$t('settings.lang_users')" v-model="apiUser"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field dense outlined :label="$t('settings.lang_user_password')" v-model="apiPassword"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-toolbar flat>
            <v-toolbar-title>{{ $t('settings.lang_guestAccount_collectiveAccount') }}</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="2" sm="6">
                <v-text-field :disabled="loading" v-model="guestAccount" dense outlined :label="$t('settings.lang_collectiveAccount')" type="number"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn color="success" @click="saveSettings" :loading="loading" :disabled="loading">
              {{ $t('generic.lang_save') }}
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn color="primary" @click="testAuthentication" :loading="loading" :disabled="loading || apiHost.length === 0 || apiUser.length === 0 || apiPassword.length === 0">{{ $t('settings.lang_testAccessData') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "Suite8Settings",

  data() {
    return {
      loading: false,

      apiHost: "",
      apiUser: "",
      apiPassword: "",

      guestAccount: 0
    }
  },

  mounted() {
    this.getSettings();
  },

  methods: {
    getSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SUITE8.SETTINGS.GET).then((res) => {
        if(res.data.success) {
          this.apiHost = res.data.settings.apiHost;
          this.apiUser = res.data.settings.apiUser;
          this.apiPassword = res.data.settings.apiPassword;
          this.guestAccount = res.data.settings.guestAccount;
        }
        else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    saveSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SUITE8.SETTINGS.UPDATE, {
        apiHost: this.apiHost,
        apiUser: this.apiUser,
        apiPassword: this.apiPassword,
        guestAccount: this.guestAccount
      }).then((res) => {
        if(res.data.success) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        }
        else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    testAuthentication() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SUITE8.TESTAUTHENTICATION).then((res) => {
        if(res.data.success) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
        }
        else {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        }
      }).finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>