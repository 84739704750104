<template>
  <div>
    <page-title heading="Suite8" subheading="Oracle Suite8" :icon=icon></page-title>
    <div class="app-main__inner">
      <Suite8Settings></Suite8Settings>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import Suite8Settings from "@/components/settings/moduleSettings/Suite8Settings";

export default {
  components: {
    Suite8Settings,
    PageTitle
  },

  data: () => ({
    icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
  })
}
</script>
